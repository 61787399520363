import { LineChart } from "@mui/x-charts";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Topbar from "../common/Topbar";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import axios from "axios";
import { useTheme } from "@mui/material/styles"; // Import useTheme hook
import { useApis } from "../../../context/apiContext";
import { authHeader } from "../../../services/httpServices/axiosService";

export default function AverageFootTrackHour({
  dateTimeRange,
  houseDeviceId,
  countType,
  countDown,
}) {
  const xHourData = Array.from({ length: 24 }, (_, i) => i);
  const xDayData = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const { callPipeline } = useApis();
  const theme = useTheme(); // Get the theme object
  const primaryColor = theme.palette.primary.main; // Access the primary color

  // const getYbaseVals = () =>
  //   countType === "hourly" ? Array(24).fill(0) : Array(7).fill(0);

  // const [xData, setXData] = useState(xDayData);
  // const [yData, setYData] = useState(Array(7).fill(0));
  const [data, setData] = useState([]);
  async function search(countType) {
    const api = callPipeline();

    api.try = async (api) => {
      const { data: result } = await api.get(
        `/agent/humanGraph/avgFootTraffic`,
        {
          params: {
            houseDeviceId,
            startDate: dateTimeRange.start,
            endDate: dateTimeRange.end,
            countType,
          },
          ...authHeader(),
        }
      );

      // const yBaseValues =
      //   countType === "hourly" ? Array(24).fill(0) : Array(7).fill(0);
      const xData = countType === "hourly" ? xHourData : xDayData;
      const baseValues = xData.map((val) => ({ x: val, y: 0 }));
      console.log("y vals base", baseValues);
      Object.keys(result).forEach((time) => {
        baseValues[time].y = result[time];
      });

      console.log("y vals", baseValues);
      setData(baseValues);
    };
    await api.run(false);
  }
  useEffect(() => {
    // if (countType === "daily") setXData(xDayData);
    // else setXData(xHourData);
    search(countType);
  }, [dateTimeRange, countType]);

  useEffect(() => {
    if (countDown === 0) search(countType);
  }, [countDown]);

  return (
    <div>
      <div className="ms-3">
        <LineChart
          skipAnimation
          dataset={data}
          grid={{ horizontal: true }}
          title="foot traffic"
          width={600}
          height={300}
          series={[
            {
              dataKey: "y",
              label: `${countType} average foot traffic `,
              color: primaryColor,
              curve: "linear",
            },
          ]}
          yAxis={[{ min: 0, label: "count" }]}
          xAxis={[
            {
              dataKey: "x",
              label: "time",
              scaleType: countType === "hourly" ? "band" : "point",
            },
          ]}
        />
      </div>
    </div>
  );
}
