import { Backdrop, CircularProgress } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";
import { useState, createContext, useContext } from "react";

export const userInfoContext = createContext();

export const UserInfoProvider = ({ children }) => {
  // User info variables
  const baseUserInfo = {
    given_name: "",
    family_name: "",
    phone_number: "",
    email: "",
    userId: null,
    isAdmin: false,
    userLoaded: false,
  };
  const [userInfo, setUserInfo] = useState(baseUserInfo);

  return (
    <userInfoContext.Provider value={[userInfo, setUserInfo]}>
      {children}
    </userInfoContext.Provider>
  );
};

export const useUserInfo = () => {
  const context = useContext(userInfoContext);

  return context;
};
