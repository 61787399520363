import React, { useEffect, useState } from "react";
import Topbar from "../common/Topbar";
import DeviceHub from "@mui/icons-material/DeviceHub";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import AddIcon from "@mui/icons-material/Add";
import DeviceCard from "./deviceCard/DeviceCard";
import { toast } from "react-toastify";
import { useUserInfo } from "../../../context/useUserInfo";
import NewDeviceCard from "./deviceCard/NewDeviceCard";
import { useApis } from "../../../context/apiContext";
import { authHeader } from "../../../services/httpServices/axiosService";
import { generateRandomString } from "../../../function/utility";
import { delay } from "@okta/okta-auth-js";
import { deviceRoutes } from "../../../services/httpServices/devices";
import Properties from "./../properties/Properties";
import CustomDialog from "./dialogs/PropertiesDialog";
import Customers from "../customers/Customers";
import { useNavigate } from "react-router-dom";

const icons = {
  address: <HomeIcon sx={{ color: "#388E3C" }} />,
  serialNumber: <Grid3x3Icon sx={{ color: "#9C27B0" }} />,
  model: <CameraIndoorIcon sx={{ color: "#FF9800" }} />,
  user: <PersonIcon color="info" />,
};

export default function Devices() {
  const nav = useNavigate();

  const [openPropertyModal, setOpenPropertyModal] = useState(false);
  const handleOpenPropertyModal = () => {
    setOpenPropertyModal(true);
  };

  const handleClosePropertyModal = () => {
    setOpenPropertyModal(false);
  };

  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const handleOpenCustomerModal = () => {
    setOpenCustomerModal(true);
  };

  const handleCloseCustomerModal = () => {
    setOpenCustomerModal(false);
  };

  const baseNewDevice = {
    serialNumber: "",
    model: "",
  };
  const { callDevice } = useApis();
  const [userInfo, setUserInfo] = useUserInfo();
  const [newDevice, setNewDevice] = useState(null);
  const isAdmin = userInfo.isAdmin;

  const [editIndex, setEditIndex] = useState(-1);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [searchQuery, setSearchQuery] = useState({
    text: "",
  });

  const [archiveDevices, setArchiveDevices] = useState([]);
  const [devices, setDevices] = useState([]);

  function getMyDevices() {
    const deviceApi = callDevice();
    deviceApi.try = async (api) => {
      const { data } = await api.get(
        deviceRoutes["listMyDevices"],
        authHeader()
      );

      const result = data.map((obj) => ({
        address: obj.address,
        deviceId: obj.deviceId,
        model: obj.name,
      }));
      setDevices(result);
    };
    deviceApi.run();
  }
  async function getAllDevices(query) {
    const deviceApi = callDevice();
    deviceApi.try = async (api) => {
      const url = deviceRoutes["listAllDevices"];

      const params = {};
      if (query && query.text) params.address = query.text;

      const { data } = await api.get(url, { params, ...authHeader() });

      const result = data.map((obj) => ({
        address: obj.address,
        deviceId: obj.deviceId,
        model: obj.name,

        serialNumber: obj.serialNumber,
        customerId: obj.customerId,
      }));
      setDevices(result);
    };
    deviceApi.run();
  }

  function updateSearchQuery(key, value) {
    const newQuery = { ...searchQuery, [key]: value };
    setSearchQuery(newQuery);
    return newQuery;
  }
  function search(query) {
    if (!userInfo.isAdmin) getMyDevices();
    else getAllDevices(query);
  }
  function handleDeviceTextChange(index, key, value) {
    const devicesCopy = [...devices];
    const thisDevice = { ...devicesCopy[index] };
    thisDevice[key] = value;

    devicesCopy[index] = thisDevice;
    setDevices(devicesCopy);
  }
  function handleCancleEdit() {
    setDevices(archiveDevices);
    setEditIndex(-1);
  }
  async function upsertDevice(name, serialNumber, deviceId = null) {
    const deviceApi = callDevice();
    const postObject = { name, serialNumber };
    if (deviceId != null) postObject.id = deviceId;

    deviceApi.try = async (api) => {
      await api.post(deviceRoutes["upsertDevice"], postObject, authHeader());
      toast.success("changes saved");
      await delay(1000);
      window.location.reload();
    };
    await deviceApi.run();
  }
  async function handleSaveEdit() {
    const device = devices[editIndex];
    await upsertDevice(device.model, device.serialNumber, device.deviceId);
  }

  function handleDeleteDevice() {
    const { deviceId } = devices[editIndex];
    const deviceApi = callDevice();
    deviceApi.try = async (api) => {
      await api.post(deviceRoutes["removeDevice"], { deviceId }, authHeader());
      toast.error("device deleted");
      await delay(1000);
      window.location.reload();
    };
    deviceApi.run();
  }
  function subscribeDevice(deviceId) {
    const deviceApi = callDevice();
    deviceApi.try = async (api) => {
      const { data } = await api.post(
        deviceRoutes["subDevice"],
        { deviceId },
        authHeader()
      );
      window.location.reload();
    };
    deviceApi.run();
  }
  function unSubscribeDevice(deviceId) {
    const deviceApi = callDevice();
    deviceApi.try = async (api) => {
      const { data } = await api.post(
        deviceRoutes["unSubDevice"],
        { deviceId },
        authHeader()
      );
      window.location.reload();
    };
    deviceApi.run();
  }
  function handleDeviceSub(index) {
    const device = devices[index];
    if (device.isSubscribed) unSubscribeDevice(device.deviceId);
    else subscribeDevice(device.deviceId);
  }
  function handleNewDeviceTextChange(key, value) {
    setNewDevice((perv) => ({ ...perv, [key]: value }));
  }
  function handleCancelNewDevice() {
    setNewDevice(null);
  }
  function handleSaveNewDevice() {
    upsertDevice(newDevice.model, newDevice.serialNumber);
  }

  async function assignProperty(propertyId, deviceId) {
    let result = null;
    const deviceApi = callDevice();
    deviceApi.try = async (api) => {
      const { data } = await api.post(
        deviceRoutes["assignProperty"],
        { deviceId, propertyId },
        authHeader()
      );
      console.log(data);
      result = data;
    };
    await deviceApi.run();
    return result;
  }
  async function assignCustomerToDevice(customerId, deviceId) {
    let result = null;
    console.log("hello!!");
    const deviceApi = callDevice();

    deviceApi.try = async (api) => {
      const { data } = await api.post(
        deviceRoutes["subDeviceForUser"],
        { deviceId, customerId },
        authHeader()
      );
      console.log(data);
      result = data;
    };

    await deviceApi.run();

    return result;
  }

  async function unassignCustomerToDevice(deviceId, customerId) {
    let result;
    const deviceApi = callDevice();
    deviceApi.try = async (api) => {
      const { data } = await api.post(
        deviceRoutes["unsubDeviceForUser"],
        { deviceId, customerId },
        authHeader()
      );
      result = data;
    };
    await deviceApi.run();
    return result;
  }
  async function unAssignProperty(deviceId) {
    const deviceApi = callDevice();
    deviceApi.try = async (api) => {
      const { data } = await api.post(
        deviceRoutes["unAssignProperty"],
        { deviceId },
        authHeader()
      );
      console.log(data);
    };
    await deviceApi.run();
  }

  async function handleDeviceAdressSelect() {
    const { address, deviceId } = devices[editIndex];
    if (address === "") handleOpenPropertyModal();
    else {
      await unAssignProperty(deviceId);
      toast.success("unassigned this property");
      await delay(1000);
      window.location.reload();
    }
  }

  async function handleDeviceCustomerSelect(index) {
    const device = devices[index];

    if (device.customerId) {
      const result = await unassignCustomerToDevice(
        device.deviceId,
        device.customerId
      );
      if (!result) return;
      toast.success("unsubbed user from device");
      await delay(1000);
      return window.location.reload();
    }

    setSelectedDevice(device);
    handleOpenCustomerModal();
  }

  async function handleViewGraph(deviceId) {
    const deviceApi = callDevice();
    deviceApi.try = async (api) => {
      const url = deviceRoutes["getHouseProperty"];

      const { data: propertyDeviceId } = await api.get(url, {
        params: { deviceId },
        ...authHeader(),
      });
      nav(`/panel/houseProperty/${propertyDeviceId}/graphs`);
    };
    deviceApi.run();
  }
  async function selectProperty(property) {
    const { deviceId } = devices[editIndex];
    const { propertyId } = property;
    const result = await assignProperty(propertyId, deviceId);
    if (!result) return;

    toast.success("assigned this property");
    await delay(1000);
    window.location.reload();
  }
  async function selectCustomer(customer) {
    const result = await assignCustomerToDevice(
      customer.customerId,
      selectedDevice.deviceId
    );
    if (!result) return;

    toast.success("assigned customer to device");
    await delay(1000);
    window.location.reload();
  }

  useEffect(() => {
    if (userInfo.userLoaded) search(searchQuery);
  }, [userInfo]);

  useEffect(() => {
    if (editIndex !== -1) setArchiveDevices(devices);
  }, [editIndex]);

  const searchField = (
    <TextField
      label="Search Address"
      value={searchQuery.text}
      onChange={(e) => updateSearchQuery("text", e.target.value)}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => {
              search(searchQuery);
            }}
          >
            <SearchIcon />
          </IconButton>
        ),
      }}
    />
  );

  return (
    <div>
      <Topbar
        icon={<DeviceHub />}
        header={userInfo.isAdmin ? "Devices" : "My Devices"}
        caption="Manage Your GenieBox devices"
      ></Topbar>
      {/* <Box display="flex" justifyContent="center" mb={1}>
        {searchField}
      </Box> */}

      <Box p={2}>
        {/* {!isAdmin && (
          <Chip
            sx={{ cursor: "pointer" }}
            onClick={() => {
              const newQuery = updateSearchQuery(
                "isSubscribed",
                !searchQuery.isSubscribed
              );
              search(newQuery);
            }}
            icon={<PersonIcon />}
            label="My Devices"
            variant={searchQuery.isSubscribed ? "filled" : "outlined"}
          />
        )} */}
        {isAdmin && !newDevice && (
          <Button
            onClick={() => {
              setNewDevice(baseNewDevice);
            }}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add new device
          </Button>
        )}
      </Box>

      <Box>
        <Grid container flexWrap={"wrap"} spacing={2} p={1}>
          {newDevice && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <NewDeviceCard
                device={newDevice}
                onTextChange={handleNewDeviceTextChange}
                onCancelEdit={handleCancelNewDevice}
                onSaveEdit={handleSaveNewDevice}
                icons={icons}
              />
            </Grid>
          )}
          {devices.map((device, i) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
              <DeviceCard
                onViewGraphs={handleViewGraph}
                onDeviceAddressSelect={handleDeviceAdressSelect}
                onDeviceCustomerSelect={handleDeviceCustomerSelect}
                icons={icons}
                onDeviceSub={handleDeviceSub}
                onCancelEdit={handleCancleEdit}
                onSaveEdit={handleSaveEdit}
                onDeleteDevice={handleDeleteDevice}
                index={i}
                editIndex={editIndex}
                setEditIndex={setEditIndex}
                device={device}
                onTextChange={handleDeviceTextChange}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {openPropertyModal && (
        <CustomDialog
          open={openPropertyModal}
          handleClose={handleClosePropertyModal}
          heading="Choose property"
          RenderPage={Properties}
          onSelectButton={selectProperty}
        />
      )}

      <CustomDialog
        open={openCustomerModal}
        handleClose={handleCloseCustomerModal}
        heading="Choose customer"
        RenderPage={Customers}
        onSelectButton={selectCustomer}
      />
    </div>
  );
}
