import { LineChart } from "@mui/x-charts";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Topbar from "../common/Topbar";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import axios from "axios";
import TotalFootTrack from "./TotalFootTrack";
import AverageFootTrackHour from "./AverageFootTrackHour";
import { Button, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import GraphDateRange from "./GraphDateRange";
import dayjs from "dayjs";
import { getSecondDifference } from "../../../function/utility";

export default function DeviceGraph() {
  const { houseDeviceId } = useParams();
  const initCountDown = 5;
  const [countDown, setCountDown] = useState(initCountDown);
  const [countType, setCountType] = useState("daily"); //daily OR hourly
  const baseDateTimeRange = {
    start: dayjs().subtract(3, "month").startOf("day"),
    end: dayjs().endOf("day"),
  };

  const [dateTimeRangeHolder, setDateTimeRangeHolder] =
    useState(baseDateTimeRange);

  const [dateTimeRange, setDateTimeRange] = useState({
    start: new Date(baseDateTimeRange.start),
    end: new Date(baseDateTimeRange.end),
  });

  function changeActualDate() {
    const copy = {
      start: new Date(dateTimeRangeHolder.start),
      end: new Date(dateTimeRangeHolder.end),
    };

    setDateTimeRange(copy);
  }
  const graphs = [
    <TotalFootTrack dateTimeRange={dateTimeRange} />,
    <AverageFootTrackHour dateTimeRange={dateTimeRange} />,
  ];

  useEffect(() => {
    setTimeout(() => {
      console.log(countDown);
      if (countDown !== 0) setCountDown((c) => c - 1);
      else setCountDown(initCountDown);
    }, 1000);
  }, [countDown]);
  useEffect(() => {
    const diff = getSecondDifference(dateTimeRange.start, dateTimeRange.end);
    if (diff <= 86400) setCountType("hourly");
  }, [dateTimeRange]);
  return (
    <div>
      <Topbar
        icon={<AutoGraphIcon className="mx-1" />}
        header={"Device Graphs"}
        caption={`analyse your houses`}
        endElement={
          <div>
            <GraphDateRange
              onSearch={changeActualDate}
              dateTimeRange={dateTimeRangeHolder}
              setDateTimeRange={setDateTimeRangeHolder}
            />
          </div>
        }
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <Grid container>
            {graphs.map((graph, i) => {
              const component = React.cloneElement(graph, {
                dateTimeRange,
                houseDeviceId,
                countType,
                countDown,
              });
              return (
                <Grid key={i} item>
                  {component}
                </Grid>
              );
            })}
          </Grid>
        </div>
        <ToggleButtonGroup
          color="secondary"
          value={countType}
          exclusive
          onChange={(e, value) => {
            if (value !== null) setCountType(value);
            // search(value);
          }}
        >
          <ToggleButton value="hourly">hourly</ToggleButton>
          <ToggleButton value="daily">daily</ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
}
