import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

export default function PropertyCardListItems({
  icons,
  propertyInfo,
  currentlyEditing,
  index,
  onTextChange,
}) {
  const textHeight = "20px";
  return (
    <div>
      <List
        dense
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <ListItem disableGutters>
          <ListItemIcon sx={{ minWidth: 32 }}>{icons.address}</ListItemIcon>
          <ListItemText
            primary={<Typography variant="body2">address</Typography>}
            secondary={
              currentlyEditing ? (
                <TextField
                  style={{ height: textHeight }}
                  // label={key}
                  name="address"
                  variant="standard"
                  value={propertyInfo.address}
                  onChange={(e) => {
                    onTextChange(index, "address", e.target.value);
                  }}
                  fullWidth
                />
              ) : (
                <Typography
                  style={{
                    height: textHeight,
                  }}
                  variant="body2"
                >
                  {propertyInfo.address}
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemIcon sx={{ minWidth: 32 }}>{icons.name}</ListItemIcon>
          <ListItemText
            primary={<Typography variant="body2">name</Typography>}
            secondary={
              currentlyEditing ? (
                <TextField
                  style={{ height: textHeight }}
                  // label={key}
                  name="name"
                  variant="standard"
                  value={propertyInfo.name}
                  onChange={(e) => {
                    onTextChange(index, "name", e.target.value);
                  }}
                  fullWidth
                />
              ) : (
                <Typography
                  style={{
                    height: textHeight,
                  }}
                  variant="body2"
                >
                  {propertyInfo.name}
                </Typography>
              )
            }
          />
        </ListItem>
      </List>
    </div>
  );
}
