import axios from "axios";

import { commonHandler } from "./axiosService";

const chatAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CHAT_API,
});
export const chatRoutes = {
  getTicket: "/users/tickets/get-ticket",
  adminGetTickets: "/dashboardAdmins/tickets/get-tickets",
  createNewTicket: "/customers/tickets/create-ticket",
  assignTicket: "/dashboardAdmins/tickets/assign-ticket",
  sendTicketMsg: "/users/tickets/send-ticket-message",
  customerGetTickets: "/customers/tickets/get-my-tickets",
  getTicketCategories: "/users/ticketCategories/get-all",
  closeTicket: "/customers/tickets/closeTicket",
};

export const callChat = commonHandler(chatAxiosInstance);
