import { LineChart } from "@mui/x-charts";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Topbar from "../common/Topbar";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import axios from "axios";

import { getSecondDifference } from "../../../function/utility";

import { useOktaAuth } from "@okta/okta-react";
import { authHeader } from "../../../services/httpServices/axiosService";
import { useApis } from "../../../context/apiContext";

export default function TotalFootTrack({
  dateTimeRange,
  houseDeviceId,
  countType,
  countDown,
}) {
  const { callPipeline } = useApis();

  const [data, setData] = useState([]);
  let pervXSvg = null;
  const minXSvg = 1;

  function fakeSearch() {
    const response = [
      {
        count: 4,
        date: "2024-04-17T12:30:00.000Z",
      },
      {
        count: 5,
        date: "2024-04-17T13:45:00.000Z",
      },
      {
        count: 6,
        date: "2024-04-17T14:50:00.000Z",
      },
      {
        count: 2,
        date: "2024-04-18T12:36:11.000Z",
      },
      {
        count: 3,
        date: "2024-04-19T12:36:11.000Z",
      },
      {
        count: 2,
        date: "2024-04-20T12:36:11.000Z",
      },
      {
        count: 5,
        date: "2024-04-21T12:36:11.000Z",
      },
      {
        count: 10,
        date: "2024-04-22T12:36:11.000Z",
      },
      {
        count: 5,
        date: "2024-04-23T12:36:11.000Z",
      },
      {
        count: 3,
        date: "2024-04-24T12:36:11.000Z",
      },
      {
        count: 19,
        date: "2024-04-25T12:36:11.000Z",
      },
    ];
    const values = response.map((obj) => ({
      date: new Date(obj.date),
      count: obj.count,
    }));

    setData(values);
  }

  async function search(countType) {
    const apiCall = callPipeline();
    apiCall.try = async (api) => {
      const { data: response } = await api.get(
        `/agent/humanGraph/footTraffic`,
        {
          params: {
            houseDeviceId,
            countType,
            startDate: dateTimeRange.start,
            endDate: dateTimeRange.end,
          },
          ...authHeader(),
        }
      );

      const values = response.map((obj) => {
        const date = new Date(obj.date);
        if (countType === "daily") date.setHours(0, 0, 0, 0);
        else date.setMinutes(0, 0, 0);
        return { date, count: obj.count };
      });
      console.log(values);

      setData(values);
    };
    await apiCall.run(false);
  }
  useEffect(() => {
    search(countType);
  }, [dateTimeRange, countType]);

  useEffect(() => {
    if (countDown === 0) search(countType);
  }, [countDown]);

  // TODO: find why it gets called twice when every state changes
  function showMark({ x, index }) {
    if (pervXSvg === null) {
      // If it's the first data point, always show the mark
      pervXSvg = x;
      return true;
    } else {
      // Compare current x value with the previous one
      const shouldShowMark = x > pervXSvg + minXSvg;
      // console.log(x, pervXSvg, shouldShowMark);
      pervXSvg = x; // Update previous x value for next comparison
      return shouldShowMark;
    }
  }

  return (
    <div>
      <div className="ms-3">
        <LineChart
          skipAnimation
          dataset={data}
          grid={{ horizontal: true }}
          title="foot traffic"
          width={500}
          height={300}
          series={[
            {
              dataKey: "count",
              label: "foot traffic",
              // showMark: showMark,
            },
          ]}
          yAxis={[{ min: 0, label: "count" }]}
          xAxis={[
            {
              dataKey: "date",
              scaleType: "time",
              label: "date",
            },
          ]}
        />
      </div>
    </div>
  );
}
