export async function post(
  url = "", 
  data = {},
  postHeaders = {"Content-Type": "application/json", "Accept": "application/json"}
  ) {
  // console.log('entered post()');
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, same-origin
    cache: "default", // default, no-cache, reload, force-cache, only-if-cached
    credentials: "omit", // include, same-origin, omit
    headers: postHeaders,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response;
}

export async function get(
  url = "", 
  getHeaders = {"Content-Type": "application/json", "Accept": "application/json"}
  ) {
  const response = await fetch(url, {
    method: "GET",
    mode: "cors", // no-cors, cors, same-origin
    cache: "default", // default, no-cache, reload, force-cache, only-if-cached
    credentials: "omit", // include, same-origin, omit
    headers: getHeaders,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer"
  });
  return response;
};

export async function checkLogin(check_token) {
  const PRODUCTION_INTROSPECT = 'https://datadoor.app/prox/introspect';
  const LOCALHOST_INTROSPECT = 'http://localhost/prox/introspect';
  const INTROSPECT_URI = process.env.NODE_ENV === "development" ? LOCALHOST_INTROSPECT : PRODUCTION_INTROSPECT;

  const introspectData = {
    token: check_token,
    token_type_hint: "access_token"
  }

  const introspectRes = await fetch(INTROSPECT_URI, {
    method: "POST", // GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, same-origin
    cache: "default", // default, no-cache, reload, force-cache, only-if-cached
    credentials: "omit", // include, same-origin, omit
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(introspectData), // body data type must match "Content-Type" header
  });

  introspectRes.json().then((jsonData) => {
    if (!jsonData.active) {
      console.log("checkLogin FALSE")
      return false;
    }
    else {
      console.log("checkLogin TRUE");
      return true;
    }
  });
}