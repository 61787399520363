import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation, useNavigate } from "react-router-dom";
import GridViewIcon from "@mui/icons-material/GridView";
import ExploreIcon from "@mui/icons-material/Explore";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import PersonIcon from "@mui/icons-material/Person";
import React from "react";
import HouseIcon from "@mui/icons-material/House";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ForumIcon from "@mui/icons-material/Forum";
import { Typography } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { toast } from "react-toastify";
import { useUserInfo } from "./../context/useUserInfo";
import { useGlobalLoading } from "./../context/globalLoadingContext";
const Navmenu = ({ handleClose }) => {
  const [_, setGlobalLoading] = useGlobalLoading();
  const { oktaAuth } = useOktaAuth();
  const userInfo = useUserInfo()[0];

  const nav = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      setGlobalLoading(true);
      // Call the logout function with optional configuration
      await oktaAuth.signOut({ postLogoutRedirectUri: window.origin });
    } catch (error) {
      toast.error("error during logout");
      console.error("Error during logout:", error);
      // Handle logout errors appropriately (e.g., display an error message)
    } finally {
      setGlobalLoading(false);
    }
  };

  return (
    <div className="navmenu">
      <div className="navmenu_top">
        <div className="mb-2">
          <img
            src={"/icons/dd-logo--compact.svg"}
            className="navmenu_logo"
            alt="logo"
          />
        </div>

        <PanelNavLink
          icon={<SettingsIcon />}
          label={"User Settings"}
          to={"settings"}
        />
        <PanelNavLink
          icon={<DeviceHubIcon />}
          label={"Devices"}
          to={"devices"}
        />
        <PanelNavLink
          icon={<HouseIcon />}
          label={"Properties"}
          to={"properties"}
          onlyAdmin={true}
        />
        <PanelNavLink
          icon={<PersonIcon />}
          label={"Customers"}
          to={"customers"}
          onlyAdmin={true}
        />
        <PanelNavLink icon={<ForumIcon />} label={"Tickets"} to={"tickets"} />
      </div>

      <div className="navmenu_bottom">
        <NavMenuLink
          icon={<LogoutIcon />}
          label={"Log Out"}
          onClick={handleLogout}
        />
        <PanelNavLink icon={<HelpOutlineIcon />} label={"support"} />
      </div>
    </div>
  );

  function NavMenuLink({ label, icon, to, onClick, base, onlyAdmin }) {
    if (onlyAdmin && !userInfo.isAdmin) return;

    base = base ? base + "/" : "";
    let urlPart = location.pathname.split("/");
    urlPart = urlPart[urlPart.length - 1];

    const color = urlPart == to ? "primary" : "disabled";
    icon = React.cloneElement(icon, {
      color,
      fontSize: "large",
    });

    return (
      <div
        className="navmenu_link"
        onClick={() => {
          if (to) nav(`/${base}${to}`);
          else if (onClick) {
            onClick();
          }
          handleClose();
        }}
      >
        <div className="">{icon}</div>
        <Typography variant="body2">{label}</Typography>
      </div>
    );
  }
  function PanelNavLink({ label, icon, to, onClick, onlyAdmin }) {
    return (
      <NavMenuLink
        base="panel"
        label={label}
        icon={icon}
        to={to}
        onClick={onClick}
        onlyAdmin={onlyAdmin}
      />
    );
  }
};

export default Navmenu;
