import { Navigate, Routes, Route } from "react-router-dom";
import "./App.css";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import Panel from "./components/panel/Panel";
import ForgotPassword from "./pages/ForgotPassword";
import Checkout from "./pages/Checkout";
import SecureRoute from "./components/login/SecureRoute";
import Login from "./components/login/Login";
import { useEffect } from "react";
import { useUserInfo } from "./context/useUserInfo";
import { updateUserInfo } from "./function/utility";
import Signup from "./components/signup/Signup";

const App = () => {
  const { authState, oktaAuth } = useOktaAuth();

  const [userInfo, setUserInfo] = useUserInfo();

  useEffect(() => {
    async function run() {
      //TODO: remove this
      console.log(process.env);
      if (authState && authState.isAuthenticated) {
        const accessToken = oktaAuth.getAccessToken();
        console.log(accessToken);
        updateUserInfo(accessToken, setUserInfo);
      }
    }
    run();
  }, [authState, oktaAuth]);

  return (
    <div className="main-app">
      <Routes>
        {/* Authentication */}
        <Route path="/login" element={<Login />} />
        <Route path={"/login/callback"} element={<LoginCallback />} />

        <Route path="/signup" element={<Signup />} />
        {/* <Route path="/session" element={<Session />} /> */}
        <Route path="/forgotpass" element={<ForgotPassword />} />

        {/* Services */}

        <Route
          path="/panel/*"
          element={
            <SecureRoute>
              <Panel />
            </SecureRoute>
          }
        />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
      </Routes>
    </div>
  );
};

export default App;
