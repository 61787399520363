import {
  Button,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import HomeIcon from "@mui/icons-material/Home";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { useUserInfo } from "../../../../context/useUserInfo";
export default function NewPropertyCard({
  property,
  onTextChange,
  onCancelEdit,
  onSaveEdit,
  icons,
}) {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        flexDirection: "column",
        display: "flex",
        justifyContent: "space-between",
        height: "100%",
        position: "relative",
        width: "100%",
      }}
    >
      <Typography variant="h5" fontFamily={"Roboto"}>
        New Property
      </Typography>
      <List
        dense
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        {Object.entries(property).map(([key, value], j) => {
          const textHeight = "20px";
          return (
            <ListItem key={j} disableGutters>
              <ListItemIcon sx={{ minWidth: 32 }}>{icons[key]}</ListItemIcon>
              <ListItemText
                primary={<Typography variant="body2">{key}</Typography>}
                secondary={
                  <TextField
                    style={{ height: textHeight }}
                    // label={key}
                    name={key}
                    variant="standard"
                    value={value}
                    onChange={(e) => {
                      onTextChange(key, e.target.value);
                    }}
                    fullWidth
                  />
                }
              />
            </ListItem>
          );
        })}
      </List>

      <div
        className="mb-2"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          variant="outlined"
          color="error"
          className="mx-1"
          onClick={onCancelEdit}
          startIcon={<CancelIcon />}
        >
          cancel
        </Button>
        <Button
          onClick={onSaveEdit}
          variant="outlined"
          color="success"
          startIcon={<SaveIcon />}
        >
          save
        </Button>
      </div>
    </Paper>
  );
}
