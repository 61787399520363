import { Button } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

import React from "react";

export default function GraphDateRange({
  dateTimeRange,
  setDateTimeRange,
  onSearch,
}) {
  return (
    <div className="hstack">
      <DateTimePicker
        onChange={(val) => setDateTimeRange((d) => ({ ...d, start: val }))}
        label="start"
        value={dateTimeRange.start}
      />
      -
      <DateTimePicker
        onChange={(val) => setDateTimeRange((d) => ({ ...d, end: val }))}
        label="end"
        value={dateTimeRange.end}
      />
      <Button onClick={onSearch} className="mx-1" variant="outlined">
        search
      </Button>
    </div>
  );
}
