import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function SecureRoute({ children }) {
  const { authState, oktaAuth } = useOktaAuth();
  const nav = useNavigate();
  useEffect(() => {
    if (authState && !authState.isAuthenticated) nav("/login");
  }, [authState, oktaAuth]);
  if (!authState) return <div>loading</div>;
  return children;
}
