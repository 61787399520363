import "./styles/SubscriptionForm.css";
import { useState, useEffect } from "react";
import PlanCard from "./PlanCard.js";
// import { useDispatch, useSelector } from "react-redux";
// import { mainActions } from "../../../store/store";
import { get } from "../../../function/ajax.js";
import { useUserInfo } from "../../../context/useUserInfo.js";
import { useOktaAuth } from "@okta/okta-react";
import { TextField } from "@mui/material";

const SubscriptionForm = (props) => {
  const { planData } = props;

  // Listings dropdown menu variable
  const [listings, setListings] = useState(1);

  const [activeCardIndex, setActiveCardIndex] = useState(0);

  // Dropdown menu index
  // const menuIndex = useSelector((state) => state.signup.listings);
  // const dispatch = useDispatch();

  // const handleSetListings = (index) => {
  //   dispatch(mainActions.changeListings(index));
  // };

  const numInputChange = (e) => {
    if (e.target.value > 0) setListings(e.target.value);
  };

  return (
    <div className="subscription-form">
      <h1>Select your subscription plan</h1>
      <div>
        <label> </label>
        <TextField
          type="number"
          label="Number of Listings"
          value={listings}
          onChange={numInputChange}
        />
      </div>
      <div className="subscription-form__options">
        {planData.map((plan, index) => (
          <PlanCard
            key={index}
            tkey={String(plan.name)}
            priceId={plan.defaultPriceId}
            index={index}
            name={plan.name}
            priceTiers={plan.priceTiers}
            // listing={plan.listing}
            isSelectMode={true}
            // selected={index == activeCardIndex ? true : false} // on initial render, select first card
            onClick={setActiveCardIndex}
            features={plan.features}
            listingCount={listings}
          />
        ))}
      </div>
    </div>
  );
};

export default SubscriptionForm;
