import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "draft-js/dist/Draft.css";
import "./index.css";
import App from "./App";
// import { Provider } from "react-redux";
// import store from "./store/store";
import { GlobalLoadingProvider } from "./context/globalLoadingContext";
import { UserInfoProvider } from "./context/useUserInfo";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, useNavigate } from "react-router-dom";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { oktaConfig } from "./components/login/oktaconfig";
import { Security } from "@okta/okta-react";
import { restoreOriginalUri } from "./function/utility";
const oktaAuth = new OktaAuth(oktaConfig);
const theme = createTheme({
  palette: {
    primary: {
      main: "#DD4C91", // Replace with your hex code or color name
      dark: "#AA2E63",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GlobalLoadingProvider>
        <UserInfoProvider>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Security>
          <ToastContainer />
        </UserInfoProvider>
      </GlobalLoadingProvider>
    </LocalizationProvider>
  </ThemeProvider>
);
