import { Button, Link, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/RegisterForm.css";
import { useApis } from "../../context/apiContext";
import { delay } from "@okta/okta-auth-js";
import { toast } from "react-toastify";
const Signup = () => {
  const { callAndromeda } = useApis();
  const nav = useNavigate();
  const [signUpInfo, setSignUpInfo] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
  });
  const handleChange = (event) => {
    setSignUpInfo({ ...signUpInfo, [event.target.name]: event.target.value });
  };
  function handleSignup() {
    const apiCall = callAndromeda();
    apiCall.try = async (api) => {
      await api.post("/users", signUpInfo);
      toast.success("user created");
      delay(1000);
      nav("/login");
    };
    apiCall.run();
  }
  return (
    <div className="register-form">
      <div className="register-form__left">
        <div className="register-form__inner">
          <img
            className="dd-logo--med"
            src={"/icons/dd-logo--black--med.png"}
            alt="dd logo"
          />
          <h1>Setting up your account</h1>

          <TextField
            fullWidth
            value={signUpInfo.firstName}
            label="first name"
            name="firstName"
            className="my-2"
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            className="my-2"
            value={signUpInfo.lastName}
            label="last name"
            name="lastName"
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            className="my-2"
            value={signUpInfo.email}
            label="email"
            name="email"
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            className="my-2"
            value={signUpInfo.phone}
            label="phone number"
            name="phone"
            onChange={handleChange}
            required
          />
          <TextField
            fullWidth
            className="my-2"
            value={signUpInfo.password}
            label="password"
            type="password"
            name="password"
            onChange={handleChange}
            required
          />
          <Button
            onClick={handleSignup}
            variant="outlined"
            className="mt-3"
            fullWidth
          >
            Sign up
          </Button>

          {/* <div
            style={{ width: "100%", height: "2em", margin: "1.5em 0 1em 0" }}
          >
            <span className="pseudo-line_half-span"></span>
            <span className="pseudo-line_text">OR</span>
            <span className="pseudo-line_half-span"></span>
          </div>
          <strong className="register-form__login-prompt">
            Already have an account?
          </strong>
          <Link className="pink-btn--small" to="/login">
            Log In
          </Link> */}
        </div>
      </div>
      {/*       <div className="register-form__right">
          <h2>Get more from your yard sign.</h2>
          <h3>Make your brand and your listing a hard one to walk past.</h3>
          <img className="register-form__dd-sign" src={ddSign} />
        </div> */}
    </div>
  );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    ></div>
  );
};

export default Signup;
