import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useUserInfo } from "../../../context/useUserInfo";

const TicketHeadInfo = ({ ticket, onCloseTicket }) => {
  const userInfo = useUserInfo()[0];
  return (
    <Card variant="outlined">
      <CardHeader title={ticket.title} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2">Status:</Typography>
            <Typography variant="body1">{ticket.status}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Category:</Typography>
            <Typography variant="body1">{ticket.category.title}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Customer:</Typography>
            <Typography variant="body1">
              {ticket.customerInfo.firstName} {ticket.customerInfo.lastName} (
              {ticket.customerInfo.email})
            </Typography>
          </Grid>
          {!userInfo.isAdmin && ticket.status !== "CLOSED" && (
            <Grid item xs={6}>
              <Button onClick={onCloseTicket} variant="contained" color="error">
                Close Ticket
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TicketHeadInfo;
