import { toRelativeUrl } from "@okta/okta-auth-js";
import jwtDecode from "jwt-decode";

export function generateRandomString(len) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
export function objectToQueryString(obj) {
  const params = new URLSearchParams();

  for (const key in obj) {
    params.append(key, obj[key]);
  }

  return params.toString();
}
export function getSecondDifference(date1, date2) {
  const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  return diffInSeconds;
}
export function getIsDashboardAdmin(user) {
  const { role } = user;
  if (!role) return false;
  return role.find((str) => str === "Dashboard_Admin") ? true : false;
}
export function oktaUserToUser(oktaUser) {
  return {
    given_name: oktaUser.firstName,
    family_name: oktaUser.lastName,
    phone_number: oktaUser.phoneNumber,
    email: oktaUser.sub,
    userId: oktaUser.userId,
    isAdmin: getIsDashboardAdmin(oktaUser),
  };
}
export function updateUserInfo(accessToken, setUserInfo) {
  const oktaUser = jwtDecode(accessToken);

  const user = oktaUserToUser(oktaUser);

  setUserInfo({ ...user, userLoaded: true });
}
export function getFullName(obj) {
  return `${obj.firstName} ${obj.lastName}`;
}

export const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  window.location.replace(
    toRelativeUrl(originalUri || "/", window.location.origin)
  );
};
