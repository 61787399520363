import { TextField } from "@mui/material";
import React from "react";

export default function Topbar({ icon, header, caption, endElement }) {
  icon = React.cloneElement(icon, { style: { fontSize: "50px" } });
  return (
    <div className="topbar-wrapper">
      <div className="topbar">
        <div>{icon}</div>
        <h1 className="topbar_heading m-0">{header}</h1>
        <div></div>
        <p className="topbar_caption">{caption}</p>
      </div>
      {endElement}
    </div>
  );
}
