import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { commonHandler } from "./axiosService";

const propertyAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PROPERTY_API,
});

export const callProperty = commonHandler(propertyAxiosInstance);

export const propertyRoutes = {
  getAllProperties: "/get-all-properties",
  getProperty: "/get-property",
  upsertProperty: "/upsert-property",
  deleteProperty: "/delete-property",
};
