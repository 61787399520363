import React from "react";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function CustomDialog({
  open,
  handleClose,
  heading,
  onSelectButton,
  RenderPage,
}) {
  // const PropertyModalTranstion = React.forwardRef(function Transition(
  //   props,
  //   ref
  // ) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        // TransitionComponent={PropertyModalTranstion}
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {heading}
            </Typography>
          </Toolbar>
        </AppBar>
        <RenderPage useSelectButton={true} onSelectButton={onSelectButton} />
      </Dialog>
    </div>
  );
}
