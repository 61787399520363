import "./Panel.css";
// import more_vertical from "../resources/more-vertical.svg";
// import dashboard_pg_icon from "../resources/dashboard-black.svg";

import { useState, useEffect } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";

import jwt from "jwt-decode";

import Devices from "./devices/Devices.jsx";
import Navmenu from "./../Navmenu";

import { useGlobalLoading } from "./../../context/globalLoadingContext.js";
import useWindowSize from "../../context/useWindowSize.js";
import Settings from "./settings/Settings.jsx";
import Offcanvas from "react-bootstrap/Offcanvas";

import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { useUserInfo } from "../../context/useUserInfo.js";
import DeviceGraph from "./device/DeviceGraph.jsx";
import { useOktaAuth } from "@okta/okta-react";
import Properties from "./properties/Properties.jsx";
import Customers from "./customers/Customers.jsx";
import Tickets from "./tickets/Tickets.jsx";
import Ticket from "./tickets/Ticket.jsx";
import CreateTicket from "./tickets/CreateTicket.jsx";
const Panel = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useGlobalLoading();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useUserInfo();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isBigMode, setIsBigMode] = useState(false);
  const [windowWidth, windowHeight] = useWindowSize();

  const [showNav, setShowNav] = useState(false);
  const handleClose = () => setShowNav(false);
  const handleShow = () => setShowNav(true);

  useEffect(() => {
    // Reset navmenu if we enter BIG MODE
    if (windowWidth > 1024) {
      setIsBigMode(true);
    } else setIsBigMode(false);
  }, [windowWidth, windowHeight]);

  const navMenu = (
    <Navmenu
      handleClose={handleClose}
      isSubscribed={true}
      // logout={logout}
    />
  );

  return (
    <>
      <div className="dashboard-wrapper">
        {isBigMode && <div className="nav-layer">{navMenu}</div>}
        {!isBigMode && (
          <div>
            <IconButton onClick={handleShow}>
              <MenuIcon fontSize="large" className="nav-menu-icon" />
            </IconButton>
            <Offcanvas show={showNav} onHide={handleClose}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Menu</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{ background: "#f5f5f5" }}>
                {navMenu}
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        )}
        <div className="dashboard_body">
          <Routes>
            <Route path="settings" element={<Settings />} />
            <Route path="properties" element={<Properties />} />
            <Route path="customers" element={<Customers />} />

            <Route path="tickets/*">
              <Route path="" element={<Tickets />} />
              <Route path=":ticketId" element={<Ticket />} />
              <Route path="new-ticket" element={<CreateTicket />} />
            </Route>

            <Route path="devices/*">
              <Route path="" element={<Devices />} />
            </Route>
            <Route path="houseProperty/*">
              <Route path=":houseDeviceId/graphs" element={<DeviceGraph />} />
            </Route>
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Panel;
