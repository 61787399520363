import axios from "axios";

import { commonHandler } from "./axiosService";

const customerAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMERS_API,
});

export const callCustomer = commonHandler(customerAxiosInstance);

export const customerRoutes = {
  getAllCustomers: "/get-all-customers",
};
