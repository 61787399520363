import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useState, createContext, useContext } from "react";
import { callPipeline } from "../services/httpServices/pipeline";
import { apiContext } from "./apiContext";
import { callDevice } from "../services/httpServices/devices";
import { callAndromeda } from "../services/httpServices/andromeda";
import { callCustomer } from "./../services/httpServices/customers";
import { callPayment } from "../services/httpServices/payment";
import { callProperty } from "./../services/httpServices/property";
import { callChat } from "./../services/httpServices/chat";

export const globalLoadingContext = createContext();

export const GlobalLoadingProvider = ({ children }) => {
  const [globalLoading, setGlobalLoding] = useState(false);

  const apiFunctions = {
    callPipeline,
    callDevice,
    callAndromeda,
    callCustomer,
    callPayment,
    callProperty,
    callChat,
  };
  for (let api in apiFunctions) {
    const apiClass = apiFunctions[api](setGlobalLoding);
    apiFunctions[api] = () => new apiClass();
  }

  return (
    <globalLoadingContext.Provider value={[globalLoading, setGlobalLoding]}>
      <apiContext.Provider value={apiFunctions}>{children}</apiContext.Provider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100000 }}
        open={globalLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </globalLoadingContext.Provider>
  );
};

export const useGlobalLoading = () => {
  const context = useContext(globalLoadingContext);

  return context;
};
