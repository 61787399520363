import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { chatRoutes } from "../../../services/httpServices/chat";
import { authHeader } from "../../../services/httpServices/axiosService";
import { useApis } from "../../../context/apiContext";
import { useNavigate } from "react-router-dom";

const CreateTicket = () => {
  const nav = useNavigate();
  const { callChat } = useApis();
  const [formData, setFormData] = useState({
    title: "",
    category: "",
    message: "",
  });

  const [categories, setCategories] = useState([]);

  function fetchCategories() {
    const chatApi = callChat();

    chatApi.try = async (api) => {
      const { data } = await api.get(
        chatRoutes["getTicketCategories"],
        authHeader()
      );
      setCategories(data);
    };

    chatApi.run();
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const chatApi = callChat();

    chatApi.try = async (api) => {
      await api.post(chatRoutes["createNewTicket"], formData, authHeader());
      nav("/panel/tickets");
    };

    chatApi.run();
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <Container maxWidth="sm">
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 4 }}
        onSubmit={handleSubmit}
      >
        <Typography
          variant="h4"
          alignSelf="center"
          component="h1"
          fontFamily="Anton"
          gutterBottom
        >
          Create New Ticket
        </Typography>
        <TextField
          label="Title"
          name="title"
          variant="outlined"
          value={formData.title}
          onChange={handleChange}
          required
        />
        <FormControl variant="outlined" required>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            label="Category"
          >
            {categories.map((categoryObj, idx) => {
              return (
                <MenuItem key={idx} value={categoryObj._id}>
                  {categoryObj.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          label="Message"
          name="message"
          variant="outlined"
          value={formData.message}
          onChange={handleChange}
          required
          multiline
          rows={4}
        />
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </Box>
    </Container>
  );
};

export default CreateTicket;
