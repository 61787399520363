import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

export default function CustomerCardListItems({
  customerInfo,
  currentlyEditing,
  index,
  onTextChange,
}) {
  const textHeight = "20px";
  return (
    <div>
      <List
        dense
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <ListItem disableGutters>
          <ListItemText
            primary={<Typography variant="body2">customer id</Typography>}
            secondary={
              currentlyEditing ? (
                <TextField
                  style={{ height: textHeight }}
                  // label={key}
                  name="customerId"
                  variant="standard"
                  value={customerInfo.customerId}
                  onChange={(e) => {
                    onTextChange(index, "customerId", e.target.value);
                  }}
                  fullWidth
                />
              ) : (
                <Typography
                  style={{
                    height: textHeight,
                  }}
                  variant="body2"
                >
                  {customerInfo.customerId}
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary={<Typography variant="body2">first name</Typography>}
            secondary={
              currentlyEditing ? (
                <TextField
                  style={{ height: textHeight }}
                  // label={key}
                  name="address"
                  variant="standard"
                  value={customerInfo.firstName}
                  onChange={(e) => {
                    onTextChange(index, "firstName", e.target.value);
                  }}
                  fullWidth
                />
              ) : (
                <Typography
                  style={{
                    height: textHeight,
                  }}
                  variant="body2"
                >
                  {customerInfo.firstName}
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary={<Typography variant="body2">lastName</Typography>}
            secondary={
              currentlyEditing ? (
                <TextField
                  style={{ height: textHeight }}
                  // label={key}
                  name="name"
                  variant="standard"
                  value={customerInfo.lastName}
                  onChange={(e) => {
                    onTextChange(index, "lastName", e.target.value);
                  }}
                  fullWidth
                />
              ) : (
                <Typography
                  style={{
                    height: textHeight,
                  }}
                  variant="body2"
                >
                  {customerInfo.lastName}
                </Typography>
              )
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary={<Typography variant="body2">email</Typography>}
            secondary={
              currentlyEditing ? (
                <TextField
                  style={{ height: textHeight }}
                  // label={key}
                  name="name"
                  variant="standard"
                  value={customerInfo.email}
                  onChange={(e) => {
                    onTextChange(index, "email", e.target.value);
                  }}
                  fullWidth
                />
              ) : (
                <Typography
                  style={{
                    height: textHeight,
                  }}
                  variant="body2"
                >
                  {customerInfo.email}
                </Typography>
              )
            }
          />
        </ListItem>
      </List>
    </div>
  );
}
