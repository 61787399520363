import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
// axios.defaults.withCredentials = true;

export function authHeader() {
  const accessToken = JSON.parse(localStorage.getItem("okta-token-storage"))
    .accessToken.accessToken;

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
}
function commonErrorHandler(e) {
  console.log(e);
  const expectedError =
    e.response && e.response.status >= 400 && e.response.status < 500;
  console.log("error resposnse", e.response);
  console.log("is exptected error:", expectedError);
  if (!expectedError) {
    console.log(e);
    return toast.error("Unexpected Error", { autoClose: 1000 });
  }
  if (e && e instanceof AxiosError && e.response) {
    if (expectedError) {
      const msg = e.response.data.message;
      if (!msg) alert("no proper message for this api");
      toast.error(msg);
    }
  } else if (e.code === "ERR_NETWORK") toast.error("Network Error");
  else if (!(e instanceof AxiosError)) alert(e.message);
}
export function commonHandler(axiosInstance) {
  // Define a class to create instances of handlerWrapper with attributes
  return (setGlobalLoading) =>
    class HandlerWrapper {
      try = async () => {};
      finally = async () => {};
      catch = async () => {};
      constructor() {}

      // Execute the handler logic
      async run(showGlobalLoading = true) {
        try {
          if (showGlobalLoading) setGlobalLoading(true);
          await this.try(axiosInstance);
        } catch (e) {
          commonErrorHandler(e);
          if (this.catch) await this.catch(e);
        } finally {
          if (this.finally) await this.finally();
          if (showGlobalLoading) setGlobalLoading(false);
        }
      }
    };
}
