import { Button, Chip, IconButton, Menu, MenuItem, Paper } from "@mui/material";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import CheckIcon from "@mui/icons-material/Check";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useUserInfo } from "../../../../context/useUserInfo";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useNavigate } from "react-router-dom";

import CustomerCardListItems from "./CustomerCardListItems";
export default function CustomerCard({
  index,
  customer,
  editIndex,
  onTextChange,
  setEditIndex,
  onCancelEdit,
  onSaveEdit,
  onDeleteCustomer,
  useSelectButton,
  onSelectButton,
}) {
  const nav = useNavigate();
  const [userInfo, setUserInfo] = useUserInfo();
  const isAdmin = userInfo.isAdmin;

  const currentlyEditing = editIndex === index;

  const [manageElement, setManageElement] = useState(null);

  const handleMenu = (event) => {
    setManageElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setManageElement(null);
  };
  const manageMenu = (
    <Menu
      id="menu-appbar"
      anchorEl={manageElement}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(manageElement)}
      onClose={handleCloseMenu}
    >
      <MenuItem
        onClick={() => {
          handleCloseMenu();
          setEditIndex(index);
        }}
      >
        {isAdmin && !currentlyEditing && editIndex === -1 && (
          <Button color="info" startIcon={<EditIcon />}>
            edit
          </Button>
        )}
      </MenuItem>
    </Menu>
  );

  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        flexDirection: "column",
        display: "flex",
        justifyContent: "space-between",
        height: "100%",
        position: "relative",
        width: "100%",
      }}
    >
      {manageMenu}

      {isAdmin && (
        <IconButton
          style={{ cursor: "pointer" }}
          color="error"
          onClick={onDeleteCustomer}
          disabled={!currentlyEditing}
          sx={{
            width: "40px",
            height: "40px",
            opacity: currentlyEditing ? "1" : "0",
            alignSelf: "flex-end",
          }}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      )}
      <CustomerCardListItems
        customerInfo={customer}
        currentlyEditing={currentlyEditing}
        index={index}
        onTextChange={onTextChange}
      />
      <div
        className="mb-2"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {!currentlyEditing && !useSelectButton && (
          <Button
            endIcon={<MoreVertIcon />}
            color="info"
            onClick={handleMenu}
            variant="outlined"
          >
            Manage
          </Button>
        )}
        {useSelectButton && (
          <Button
            color="info"
            onClick={() => onSelectButton(customer)}
            variant="outlined"
          >
            select
          </Button>
        )}
        {currentlyEditing && (
          <>
            <Button
              variant="outlined"
              color="error"
              className="mx-1"
              onClick={onCancelEdit}
              startIcon={<CancelIcon />}
            >
              cancel
            </Button>
            <Button
              onClick={onSaveEdit}
              variant="outlined"
              color="success"
              startIcon={<SaveIcon />}
            >
              save
            </Button>
          </>
        )}
      </div>
    </Paper>
  );
}
