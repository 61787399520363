import React from "react";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useUserInfo } from "../../../../context/useUserInfo";
export default function DeviceCardListItems({
  icons,
  deviceInfo,
  currentlyEditing,
  index,
  onTextChange,
  onDeviceAddressSelect,
}) {
  const userInfo = useUserInfo()[0];

  const installed = !(deviceInfo.address === "");
  if (!installed) deviceInfo.address = "not installed yet";

  const textHeight = "20px";
  const capitalizeStyle = { textTransform: "capitalize" };

  return (
    <div>
      <List
        dense
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <ListItem disableGutters>
          <ListItemIcon sx={{ minWidth: 32 }}>{icons.address}</ListItemIcon>
          <ListItemText
            primary={
              <Typography style={{ ...capitalizeStyle }} variant="body2">
                address
              </Typography>
            }
            secondary={
              currentlyEditing ? (
                <Button
                  variant="contained"
                  color={installed ? "error" : "secondary"}
                  onClick={onDeviceAddressSelect}
                >
                  {installed ? "unassign property" : "assign property"}
                </Button>
              ) : (
                <Typography
                  color={installed ? "inherit" : "red"}
                  style={{
                    height: textHeight,
                  }}
                  variant="body2"
                >
                  {deviceInfo.address}
                </Typography>
              )
            }
          />
        </ListItem>
        {userInfo.isAdmin && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>
              {icons.serialNumber}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ ...capitalizeStyle }} variant="body2">
                  serial number
                </Typography>
              }
              secondary={
                currentlyEditing ? (
                  <TextField
                    style={{ height: textHeight }}
                    // label={key}
                    name="serialNumber"
                    variant="standard"
                    value={deviceInfo.serialNumber}
                    onChange={(e) => {
                      onTextChange(index, "serialNumber", e.target.value);
                    }}
                    fullWidth
                  />
                ) : (
                  <Typography
                    color={installed ? "inherit" : "red"}
                    style={{
                      height: textHeight,
                    }}
                    variant="body2"
                  >
                    {deviceInfo.serialNumber}
                  </Typography>
                )
              }
            />
          </ListItem>
        )}
        <ListItem disableGutters>
          <ListItemIcon sx={{ minWidth: 32 }}>{icons.model}</ListItemIcon>
          <ListItemText
            primary={
              <Typography style={{ ...capitalizeStyle }} variant="body2">
                model
              </Typography>
            }
            secondary={
              currentlyEditing ? (
                <TextField
                  style={{ height: textHeight }}
                  name="model"
                  variant="standard"
                  value={deviceInfo.model}
                  onChange={(e) => {
                    onTextChange(index, "model", e.target.value);
                  }}
                  fullWidth
                />
              ) : (
                <Typography
                  color={installed ? "inherit" : "red"}
                  style={{
                    height: textHeight,
                  }}
                  variant="body2"
                >
                  {deviceInfo.model}
                </Typography>
              )
            }
          />
        </ListItem>
        {userInfo.isAdmin && (
          <ListItem disableGutters>
            <ListItemIcon sx={{ minWidth: 32 }}>{icons.user}</ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ ...capitalizeStyle }} variant="body2">
                  customer id
                </Typography>
              }
              secondary={
                <Typography
                  color={installed ? "inherit" : "red"}
                  style={{
                    height: textHeight,
                  }}
                  variant="body2"
                >
                  {deviceInfo.customerId || "No customer assigned"}
                </Typography>
              }
            />
          </ListItem>
        )}
      </List>
    </div>
  );
}
