import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { commonHandler } from "./axiosService";

const andromedaAxiosInstance = axios.create({
  // withCredentials: true,
  baseURL: process.env.REACT_APP_ANDROMEDA_API,
});

export const callAndromeda = commonHandler(andromedaAxiosInstance);
