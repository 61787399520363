import "./ForgotPassPrompt.css"
import {useState} from "react";
import {post} from "../function/ajax";
import TextField from "./input/TextField";

const ForgotPass = (props) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const validateEmail = () => {
    // Email validation
    if (String(email).length === 0) {
      setEmailError('Error: Email address is required. Please enter your email address.');
      return false;
    } else if (!String(email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      setEmailError(
        'Error: Invalid email address. Please enter an address in the format "example@email.com"'
      );
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  const onSubmit = () => {
    if (!validateEmail())
      return;
    const LOCALHOST_SEND_PASS = "http://localhost/prox/forgotpass/send";
    const PROD_SEND_PASS = "https://datadoor.app/prox/forgotpass/send";
    const SEND_PASS_URI = process.env.NODE_ENV == "development" ? LOCALHOST_SEND_PASS : PROD_SEND_PASS;
    const body = {
      email: email
    };
    post(SEND_PASS_URI, body).then((response) => {
      if (response.status == 200) {
        setEmailSent(true);
      } else {
        setEmailError("Error: We couldn't find the email address that you entered. Please try again.");
      }
    });
  }

  const onClickCover = () => {
    props.onClickCover();
  }

  const onClickBox = (event) => {
    event.stopPropagation();
  }

  const promptDialogue = (
    <>
      <TextField label="*Email Address" val={email} setVal={setEmail} error={emailError} />
      <input style={{marginTop:"12px"}} type="button" className="pink-btn--large" value="Submit" onClick={onSubmit} />
    </>
  )

  const defaultMsg = <p>We’ll send you an email to reset your password.<br/>
   Input the associated email adress for your account.</p>;
  const sentMsg = <p style={{color:'#dd4c91'}}>Email sent!</p>;
  return (
    <div className="page-cover" onClick={onClickCover}>
      <div className="forgot-pass-window" onClick={onClickBox}>
        <h1>Forgot your password?</h1>
        { !emailSent ? defaultMsg : sentMsg }
        { !emailSent ? promptDialogue : null }
      </div>
    </div>
  )
}

export default ForgotPass;