import TextField from "../components/input/TextField";
import { useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { post } from "../function/ajax";

const ForgotPassword = () => {
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const [passwordError1, setPasswordError1] = useState("");
  const [passwordError2, setPasswordError2] = useState("");
  const [passSent, setPassSent] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  //const encodedEmail = encodeURI(email);

  const passCheck = () => {
    const passwordRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*d)[a-zA-Zd@$!%*#?&^]{8,}$"
    );
    if (String(newPass).length === 0) {
      setPasswordError1(
        "Error: Password is required. Please enter a password."
      );
    } else if (!passwordRegex.test(newPass)) {
      setPasswordError1(
        "Error: Invalid password. Password must be at least 8 characters, and must contain at least 1 lowercase letter, 1 uppercase letter, and 1 digit."
      );
    } else {
      setPasswordError1("");
    }

    if (newPass != confirmNewPass)
      setPasswordError2("Error: Passwords must match.");

    return false;
  };

  const onSubmit = () => {
    if (!passCheck) return;

    const LOCALHOST_CHANGE_PASS = "http://localhost/prox/forgotpass/changepass";
    const PROD_CHANGE_PASS = "https://datadoor.app/prox/forgotpass/changepass";
    const CHANGE_PASS_URI =
      process.env.NODE_ENV == "development"
        ? LOCALHOST_CHANGE_PASS
        : PROD_CHANGE_PASS;

    const email = decodeURI(searchParams.get("email"));
    const code = searchParams.get("code");

    const body = {
      email: email,
      code: code,
      new_pass: newPass,
    };

    post(CHANGE_PASS_URI, body).then((response) => {
      if (response.status == 200) {
        setPassSent(true);
      } else {
        setPasswordError1("");
        setPasswordError2(
          "Error: We could not reset your password at this time, please try again later."
        );
        response.json().then((jsonBody) => {
          console.log(jsonBody);
        });
      }
    });
  };

  return (
    <div style={{ textAlign: "center" }}>
      <img
        className="dd-logo--med"
        src={"/icons/dd-logo--black--med.png"}
        alt="dd logo"
      />
      <h1>Create New Password</h1>
      {!passSent ? (
        <div>
          <TextField
            label="New Password"
            type="password"
            value={newPass}
            setVal={setNewPass}
            error={passwordError1}
          />
          <TextField
            label="Confirm New Password"
            type="password"
            value={confirmNewPass}
            setVal={setConfirmNewPass}
            error={passwordError2}
          />
          <input
            type="button"
            className="pink-btn--large"
            value="Reset Password"
            onClick={onSubmit}
          />
        </div>
      ) : (
        <div>
          <h3>Password successfully reset!</h3>
          <Link to="/login">Return to login page</Link>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
