import { useState } from "react";

import { post } from "../../../function/ajax";
import TextField from "../../input/TextField";

const ChangePass = (props) => {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassError, setNewPassError] = useState("");
  const [oldPassError, setOldPassError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = () => {
    // Resetting error msg
    setOldPassError("");
    setNewPassError("");

    // Blank password errors
    let emptyPass = false;
    if (oldPass.length === 0) {
      setOldPassError("Passwords cannot be left blank");
      emptyPass = true;
    }
    if (newPass.length === 0) {
      setNewPassError("Passwords cannot be left blank");
      emptyPass = true;
    }
    if (emptyPass) return;

    // Password too short
    let shortPass = false;
    if (oldPass.length < 8) {
      setOldPassError("Passwords cannot be less than 8 characters long");
      shortPass = true;
    }
    if (newPass.length < 8) {
      setNewPassError("Passwords cannot be less than 8 characters long");
      shortPass = true;
    }
    if (shortPass) return;

    // Send change password request to proxy endpoint
    const LOCALHOST_CHANGEPASS_URI = "http://localhost/prox/users/changepass";
    const PRODUCTION_CHANGEPASS_URI =
      "https://datadoor.app/prox/users/changepass";
    const CHANGEPASS_URI =
      process.env.NODE_ENV == "development"
        ? LOCALHOST_CHANGEPASS_URI
        : PRODUCTION_CHANGEPASS_URI;
    const changepass_body = {
      id: props.id,
      oldPass: oldPass,
      newPass: newPass,
    };
    post(CHANGEPASS_URI, changepass_body).then((response) => {
      response.json().then((jsonRes) => {
        if (response.status == 200) {
          setIsSuccess(true);
        } else {
          // TODO: notify user if they entered the wrong password
          // Notify user that password could not be changed
          setNewPassError("Error: Your password could not be changed");
        }
      });
    });
  };

  const onClickCover = () => {
    props.setChangePassOpen(false);
  };

  const onClickBox = (event) => {
    event.stopPropagation();
  };

  const promptDialogue = (
    <>
      <TextField
        type="password"
        label="Old Password"
        val={oldPass}
        setVal={setOldPass}
        error={oldPassError}
      />
      <TextField
        type="password"
        label="New Password"
        val={newPass}
        setVal={setNewPass}
        error={newPassError}
      />
      <input
        style={{ marginTop: "12px" }}
        type="button"
        className="pink-btn--large"
        value="Submit"
        onClick={onSubmit}
      />
    </>
  );

  const successDialogue = (
    <p>
      Success! Your password has been changed.
      <br />
      Click anywhere outside this window to exit.
    </p>
  );

  return (
    <div className="page-cover" onClick={onClickCover}>
      <div className="forgot-pass-window" onClick={onClickBox}>
        <h1>Change password</h1>
        {!isSuccess ? promptDialogue : successDialogue}
      </div>
    </div>
  );
};

export default ChangePass;
