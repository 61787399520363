// src/TicketPage.js

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { chatRoutes } from "../../../services/httpServices/chat";
import { useParams } from "react-router-dom";
import { useApis } from "../../../context/apiContext";
import { authHeader } from "../../../services/httpServices/axiosService";
import TicketHeadInfo from "./TicketHeadInfo";
import { useUserInfo } from "./../../../context/useUserInfo";
import { getFullName } from "../../../function/utility";

const Ticket = () => {
  const userInfo = useUserInfo()[0];
  const { ticketId } = useParams();
  const { callChat } = useApis();
  const [message, setMessage] = useState("");
  const [ticket, setTicket] = useState({});
  const [usersInfo, setUsersInfo] = useState({});

  function populateUserInfo(ticketData) {
    const { created_by, assigned_to, customerInfo, employeeInfo } = ticketData;
    const obj = {};
    obj[created_by] = customerInfo;
    obj[assigned_to] = employeeInfo;
    console.log(obj);
    setUsersInfo(obj);
  }

  async function getTicket() {
    const chatApi = callChat();

    chatApi.try = async (api) => {
      const url = `${chatRoutes["getTicket"]}/${ticketId}`;

      const { data } = await api.get(url, {
        ...authHeader(),
      });
      console.log(data);
      setTicket(data.ticket);
      populateUserInfo(data.ticket);
    };

    await chatApi.run();
  }

  useEffect(() => {
    getTicket();
  }, []);

  function pushLastMsgToUi() {
    const ticketCopy = { ...ticket };
    ticketCopy.messages = [...ticket.messages];
    const msgObj = {
      message,
      sender_id: userInfo.userId,
      createdAt: new Date().toDateString(),
      status: "SENT",
    };
    ticketCopy.messages.push(msgObj);
    setMessage("");
    setTicket(ticketCopy);
  }
  const handleSendMessage = async () => {
    console.log("New message:", message);
    const chatApi = callChat();

    chatApi.try = async (api) => {
      await api.post(
        chatRoutes["sendTicketMsg"],
        { message, ticketId },
        authHeader()
      );

      pushLastMsgToUi();
    };

    await chatApi.run();
  };
  const handleCloseTicket = async () => {
    const ticketId = ticket._id;
    const chatApi = callChat();

    chatApi.try = async (api) => {
      await api.post(chatRoutes["closeTicket"], { ticketId }, authHeader());
      window.location.reload();
    };

    await chatApi.run();
  };

  if (Object.keys(ticket).length !== 0)
    return (
      <Box sx={{ p: 3 }}>
        <TicketHeadInfo ticket={ticket} onCloseTicket={handleCloseTicket} />

        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Messages
          </Typography>
          {ticket.messages &&
            ticket.messages.map((msg, idx) => (
              <Card key={idx} sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="h6">
                    {getFullName(usersInfo[msg.sender_id])}
                  </Typography>
                  <Typography
                    style={{ whiteSpace: "pre-wrap" }}
                    variant="body1"
                  >
                    {msg.message}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Sent at: {new Date(msg.createdAt).toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            ))}
        </Box>

        {ticket.status !== "CLOSED" && (
          <Box sx={{ mt: 4 }}>
            <TextField
              label="Write a new message"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleSendMessage}
            >
              Send
            </Button>
          </Box>
        )}
      </Box>
    );
};

export default Ticket;
