import "./TextField.css"

function TextField(props) {

    const typingHandler = (event) => {
        props.setVal(event.target.value);
    }

    const txtClassName = props.class != null ? props.class : 'text-field';

    const isDisabled = props.disabled ? true : false;
    const isNumbers = props.type == "number" ? true : false;

    const optionBtn = (
        <p className="text-field__option-btn" onClick={props.btnClick}>{props.optionBtn}</p>
    )

    return (
        <div className={txtClassName}>
            <div>
                <h2 style={{float:"left"}} className="text-field__label">{props.label}</h2>
                { props.optionBtn ? optionBtn : null }
            </div>
            <input 
                disabled={isDisabled}
                type={props.type}
                placeholder={props.placeholder} 
                className="text-field__input"
                onChange={typingHandler}
                value={props.val} />
            <div className="text-field__error">
                <label>{props.error}</label>
            </div>
        </div>
    );
}

export default TextField;