import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import "./LoginForm.css";
import EmailIcon from "@mui/icons-material/Email";
import ForgotPass from "../ForgotPassPrompt.js";
// import emailIcon from "../resources/email-icon.png";
// import logo from "../resources/dd-logo--black--med.png";
import { useOktaAuth } from "@okta/okta-react";
import { toast } from "react-toastify";
import { Button, TextField } from "@mui/material";
// import TextField from "../input/TextField.js";
import { useGlobalLoading } from "./../../context/globalLoadingContext";

const Login = () => {
  const nav = useNavigate();
  // console.log("LOGINFORM");
  const { oktaAuth, authState } = useOktaAuth();
  const setGlobalLoading = useGlobalLoading()[1];
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayForgotPass, setDisplayForgotPass] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  function loginError() {
    toast.error("Please make sure your credentials are correct and try again.");
  }

  const onForgotPass = () => {
    if (displayForgotPass) setDisplayForgotPass(false);
    else setDisplayForgotPass(true);
  };

  const signInBtnHandler = async () => {
    if (String(email).length === 0) {
      toast.error("Email address is required");
    } else if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      toast.error("Invalid email address");
    }

    const body = {
      username: email,
      password: password,
    };

    try {
      setGlobalLoading(true);
      const result = await oktaAuth.signInWithCredentials(body);
      // console.log("user", result.user);
      // console.log("result", result);
      // return;
      const sessionToken = result.sessionToken;
      if (!sessionToken) {
        toast.error("authentication process failed");
      }
      await oktaAuth.signInWithRedirect({
        originalUri: "/panel",
        sessionToken: sessionToken,
      });
    } catch (error) {
      loginError();
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => {
    if (authState && authState.isAuthenticated)
      nav("/panel", { replace: true });
  }, [oktaAuth, authState]);

  if (authState)
    return (
      <div className="login-form">
        {displayForgotPass ? <ForgotPass onClickCover={onForgotPass} /> : null}
        <img
          className="dd-logo--med"
          src={"/icons/dd-logo--black--med.png"}
          alt="dd logo"
        />
        <h1>Sign In</h1>
        <TextField
          label="email"
          autoComplete="true"
          className="my-4"
          name="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email address (ex: email@example.com)"
        />
        <TextField
          autoComplete="true"
          label="password"
          name="password"
          type="password"
          value={password}
          required
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={({ key }) => {
            if (key === "Enter") signInBtnHandler();
          }}
          placeholder="Enter your password"
        />
        <span className="login-form__forgot-pass" onClick={onForgotPass}>
          Forgot Password?
        </span>
        <Button variant="contained" className="mt-1" onClick={signInBtnHandler}>
          Sign in
        </Button>

        <div style={{ width: "100%", height: "2em", margin: "1.5em 0 1em 0" }}>
          <span className="pseudo-line_half-span"></span>
          <span className="pseudo-line_text">OR</span>
          <span className="pseudo-line_half-span"></span>
        </div>
        <div style={{ width: "100%" }}>
          <p className="login-form__login-prompt">Don't have an account?</p>
        </div>
        <Button
          onClick={() => nav("/signup")}
          color="info"
          startIcon={<EmailIcon />}
        >
          Sign Up with Email
        </Button>
      </div>
    );
};

export default Login;
