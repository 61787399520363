import { useState, useEffect } from "react";

const Checkout = () => {
  const [message, setMessage] = useState("");
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success") == "true") {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("success") == "false") {
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, []);

  return (
    <>
      <h1>{message}</h1>
    </>
  )
}

export default Checkout;