import { useOktaAuth } from "@okta/okta-react";
import { post } from "../../../function/ajax";
import "./styles/PlanCard.css";
import { useApis } from "../../../context/apiContext";
import { authHeader } from "../../../services/httpServices/axiosService";
import { Button } from "@mui/material";

function PlanCard(props) {
  const { callPayment } = useApis();
  const price = getAmountForQuantity(props.listingCount);

  function getAmountForQuantity(quantity) {
    for (const tier of props.priceTiers) {
      if (tier.upTo === null || quantity <= tier.upTo) {
        return tier.amount / 100;
      }
    }
  }

  const changePlanBtn = (
    <div className="tier-card__change-plan-box" onClick={props.changePlanBtn}>
      <p className="tier-card__change-plan-btn">Change plan</p>
    </div>
  );

  const onCheckoutClick = () => {
    const paymentApi = callPayment();
    paymentApi.try = async (api) => {
      const { data } = await api.post(
        "/subscription-checkout",
        {
          priceId: props.priceId,
          quantity: props.listingCount,
        },
        authHeader()
      );

      window.location.replace(data.url);
    };
    paymentApi.run();
  };

  const stripeCheckoutBtn = (
    <button
      className="pink-btn--large"
      key={props.tkey + "13"}
      style={{ marginBottom: "20px" }}
      onClick={onCheckoutClick}
    >
      Subscribe
    </button>
  );

  const subscribeBtn = (
    <div
      className="pink-btn--small"
      key={props.tkey + "1"}
      style={{ textAlign: "center", marginBottom: "20px", padding: "8px" }}
    >
      Subscribe
    </div>
  );

  return (
    <div
      key={props.tkey + "0"}
      className={`tier-card${
        props.selected || props.settings ? "--selected" : ""
      }`}
      style={
        props.settings
          ? {
              border: "1px solid #E5E5E5",
              boxShadow: "none",
              marginLeft: "0px",
            }
          : { color: "#020202" }
      }
      // onClick={clickHandler}
    >
      {/* {props.settings ? changePlanBtn : null} */}

      <h2 className="tier-card__name" key={props.tkey + "2"}>
        {props.name}
      </h2>

      {/* Price container */}
      <div className="tier-card__attn" key={props.tkey + "4"}>
        <p className="tier-card__attn__super-s" key={props.tkey + "5"}>
          $
        </p>
        <p className="tier-card__attn__strong" key={props.tkey + "6"}>
          {price}
        </p>
        <p className="tier-card__attn__sub-s" key={props.tkey + "7"}>
          each/month
        </p>
        {props.settings && (
          <p className="tier-card__attn__sub-s">
            number of devices: {props.listingCount}
          </p>
        )}
      </div>
      {/* Subscribe button */}
      {props.isSelectMode ? stripeCheckoutBtn : null}
      <h2 className="tier-card__h-xs" key={props.tkey + "8"}>
        Includes:
      </h2>
      <ul className="tier-card__features" key={props.tkey + "9"}>
        {props.features.map((e, i) => (
          <li key={props.tkey + "feature" + i}>{e}</li>
        ))}
      </ul>
      {props.settings && (
        <Button onClick={props.onCancelSub} variant="outlined" color="error">
          Cancel Subscription
        </Button>
      )}
    </div>
  );
}

export default PlanCard;
