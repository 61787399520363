import React, { useEffect, useState } from "react";
import Topbar from "../common/Topbar";
import DeviceHub from "@mui/icons-material/DeviceHub";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import HouseIcon from "@mui/icons-material/House";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import CameraIndoorIcon from "@mui/icons-material/CameraIndoor";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { useUserInfo } from "../../../context/useUserInfo";
import { useApis } from "../../../context/apiContext";
import { authHeader } from "../../../services/httpServices/axiosService";
import { generateRandomString } from "../../../function/utility";
import { delay } from "@okta/okta-auth-js";
import { propertyRoutes } from "../../../services/httpServices/property";
import PersonIcon from "@mui/icons-material/Person";
import CustomerCard from "./customerCard/CustomerCard";
import BadgeIcon from "@mui/icons-material/Badge";
import axios from "axios";
import {
  callCustomer,
  customerRoutes,
} from "./../../../services/httpServices/customers";

export default function Customers({
  useSelectButton = false,
  onSelectButton = () => {},
}) {
  const { callCustomer } = useApis();
  const [userInfo, setUserInfo] = useUserInfo();

  const isAdmin = userInfo.isAdmin;

  const [editIndex, setEditIndex] = useState(-1);
  const [searchQuery, setSearchQuery] = useState({
    searchPhrase: "",
  });

  const [archiveCustomers, setArchiveCustomers] = useState([]);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    if (editIndex !== -1) setArchiveCustomers(customers);
  }, [editIndex]);

  async function getAllCustomers(params) {
    const propertyApi = callCustomer();
    propertyApi.try = async (api) => {
      const { data } = await api.get(customerRoutes["getAllCustomers"], {
        ...authHeader(),
        params,
      });

      setCustomers(data);
    };
    propertyApi.run();
  }

  useEffect(() => {
    if (userInfo.userLoaded) search(searchQuery);
  }, [userInfo]);

  const searchField = (
    <TextField
      label="Search customer"
      value={searchQuery.seachPhrase}
      onChange={(e) => updateSearchQuery("searchPhrase", e.target.value)}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => {
              search(searchQuery);
            }}
          >
            <SearchIcon />
          </IconButton>
        ),
      }}
    />
  );

  function updateSearchQuery(key, value) {
    const newQuery = { ...searchQuery, [key]: value };
    setSearchQuery(newQuery);
    return newQuery;
  }
  function search(query) {
    getAllCustomers(query);
  }
  function handleCustomerTextChange(index, key, value) {
    const customersCopy = [...customers];
    const thisCustomer = { ...customersCopy[index] };
    thisCustomer[key] = value;

    customersCopy[index] = thisCustomer;
    setCustomers(customersCopy);
  }
  function handleCancleEdit() {
    setCustomers(archiveCustomers);
    setEditIndex(-1);
  }

  function handleDeleteCustomer() {}

  return (
    <div>
      {!useSelectButton && (
        <Topbar
          icon={<PersonIcon />}
          header="Customers"
          caption="Manage Customers"
        ></Topbar>
      )}
      <Box display="flex" justifyContent="center" mb={1} mt={1}>
        {searchField}
      </Box>

      <Box>
        <Grid container flexWrap={"wrap"} spacing={2} p={1}>
          {customers.map((customer, i) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
              <CustomerCard
                useSelectButton={useSelectButton}
                onSelectButton={onSelectButton}
                onCancelEdit={handleCancleEdit}
                onDeleteCustomer={handleDeleteCustomer}
                index={i}
                editIndex={editIndex}
                setEditIndex={setEditIndex}
                customer={customer}
                onTextChange={handleCustomerTextChange}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
