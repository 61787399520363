import "./styles/SettingsGrid.css";
import { useState, useEffect } from "react";
import TextField from "../../input/TextField.js";
import PlanCard from "./PlanCard";
import SubbedGenieBoxListing from "./SubbedGenieboxListing";
import ChangePassPrompt from "./ChangePassPrompt.js";
import SubscriptionForm from "./SubscriptionForm";
import Topbar from "./../common/Topbar";
import SettingsIcon from "@mui/icons-material/Settings";
import { useUserInfo } from "../../../context/useUserInfo.js";
import { useOktaAuth } from "@okta/okta-react";
import { useApis } from "../../../context/apiContext.js";
import { authHeader } from "../../../services/httpServices/axiosService.js";
import { toast } from "react-toastify";
import { delay } from "@okta/okta-auth-js";

const SettingsGrid = () => {
  const [userInfo, setUserInfo] = useUserInfo();
  const { callCustomer, callPayment } = useApis();

  // React state to store API response data
  const [planData, setPlanData] = useState([]);

  // Sub-page selection tracker
  const [isGeneral, setIsGeneral] = useState(true);
  const [isSubscription, setIsSubscription] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [isHelpcenter, setIsHelpcenter] = useState(false);
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [listings, setListings] = useState("");
  const [subInfo, setSubInfo] = useState(null);

  // Change password popup tracker
  const [changePassOpen, setChangePassOpen] = useState(false);
  // Change subscription popup tracker
  const [changeSubOpen, setChangeSubOpen] = useState(false);

  const onClickGeneral = () => {
    setIsGeneral(true);
    setIsSubscription(false);
    setIsNotification(false);
    setIsHelpcenter(false);
  };

  const onClickSubscription = () => {
    setIsGeneral(false);
    setIsSubscription(true);
    setIsNotification(false);
    setIsHelpcenter(false);
  };

  const onClickNotification = () => {
    setIsGeneral(false);
    setIsSubscription(false);
    setIsNotification(true);
    setIsHelpcenter(false);
  };

  const onClickHelpcenter = () => {
    setIsGeneral(false);
    setIsSubscription(false);
    setIsNotification(false);
    setIsHelpcenter(true);
  };

  const onChangePassClick = () => {
    // Open change password popup
    setChangePassOpen(true);
  };

  const onChangeSubClick = () => {
    // Open change susbscription popup
    setChangeSubOpen(true);
  };

  const onCoverClick = () => {
    // Close page cover when clicking outside of change subscription popup
    setChangeSubOpen(false);
  };

  const onBoxClick = (e) => {
    e.stopPropagation();
  };

  const changePassPrompt = (
    <ChangePassPrompt setChangePassOpen={setChangePassOpen} id={userInfo.id} />
  );
  function getSubInfo(plans) {
    const customerApi = callCustomer();
    customerApi.try = async (api) => {
      const { data } = await api.get("/subscription-status", authHeader());
      if (!data) return;
      const plan = plans.find((plan) => plan.id === data.product.id);
      console.log("this plan", plan);
      setSubInfo({ quantity: data.quantity, plan });
    };
    customerApi.run();
  }

  async function getPlans() {
    let result = null;
    const paymentApi = callPayment();
    paymentApi.try = async (api) => {
      const { data } = await api.get("/plans", authHeader());
      console.log("data", data);
      setPlanData(data);
      result = data;
    };
    await paymentApi.run();
    return result;
  }

  useEffect(() => {
    if (userInfo.userLoaded && !userInfo.isAdmin)
      getPlans().then((plans) => getSubInfo(plans));
  }, [userInfo]);
  const generalSettings = (
    <>
      {changePassOpen ? changePassPrompt : null}
      <h1 className="settings-body__header">General Information</h1>
      <span className="pseudo-line_full-span"></span>
      <div className="settings-body_grid">
        <TextField
          disabled="true"
          type="text"
          label="First name"
          val={userInfo.given_name}
          class="text-field--half"
          placeholder="John"
        />
        <TextField
          disabled="true"
          type="text"
          label="Last name"
          val={userInfo.family_name}
          class="text-field--half-right"
          placeholder="Doe"
        />
        <TextField
          disabled="true"
          type="text"
          label="Phone Number"
          val={userInfo.phone_number}
          placeholder="(555) 555-5555"
        />
        <TextField
          disabled="true"
          type="text"
          label="Email Address"
          val={userInfo.email}
          placeholder="john.doe@gmail.com"
        />
        <div style={{ float: "left", marginTop: "20px" }}>
          <span>Password: </span>
          <span className="change-pass-btn" onClick={onChangePassClick}>
            Click here to Change Password
          </span>
        </div>
      </div>
    </>
  );

  async function handleCancelSub() {
    const paymentApi = callPayment();
    paymentApi.try = async (api) => {
      await api.patch("/cancel-sub", null, authHeader());
      toast.done("subscription cancelled");
      await delay(1000);
      window.location.reload();
    };
    await paymentApi.run();
  }
  const nosub = subInfo ? false : true;

  const nosubView = (
    <>
      <span>No subscription active: </span>
      <span
        className="pink-btn--small"
        style={{ whiteSpace: "nowrap" }}
        onClick={onChangeSubClick}
      >
        Click here to subscribe
      </span>
    </>
  );

  const subbedView = subInfo && (
    <>
      <PlanCard
        name={subInfo.name}
        listingCount={subInfo.quantity}
        priceTiers={subInfo.plan.priceTiers}
        features={subInfo.plan.features}
        settings={true}
        changePlanBtn={onChangeSubClick}
        onCancelSub={handleCancelSub}
      />
    </>
  );

  const changeSub = (
    <div className="page-cover" onClick={onCoverClick}>
      <div className="changesub-box" onClick={onBoxClick}>
        <SubscriptionForm planData={planData} />
      </div>
    </div>
  );

  const subscriptionSettings = (
    <>
      <h1 className="settings-body__header">Subscription Plan</h1>
      <span className="pseudo-line_full-span"></span>
      <div className="settings-body__grid">
        {/* TODO: fix weird gap here on mobile screens */}
        {nosub ? nosubView : subbedView}
      </div>
      {!changeSubOpen ? null : changeSub}
    </>
  );

  return (
    <div>
      <Topbar
        icon={<SettingsIcon />}
        header={"User Settings"}
        caption={"Manage your account information"}
      />
      <div className="dashboard_body_grid">
        {!userInfo.isAdmin && (
          <div className="settings-bar">
            <div
              className="settings-bar_tab-container"
              onClick={onClickGeneral}
            >
              <p
                className="settings-bar_tab-label"
                style={isGeneral ? { color: "#DD4C91" } : {}}
              >
                General
              </p>
            </div>
            {!userInfo.isAdmin && (
              <div
                className="settings-bar_tab-container"
                onClick={onClickSubscription}
              >
                <p
                  className="settings-bar_tab-label"
                  style={isSubscription ? { color: "#DD4C91" } : {}}
                >
                  Subscription
                </p>
              </div>
            )}
            {/* <div
            className="settings-bar_tab-container"
            onClick={onClickNotification}
          >
            <p
              className="settings-bar_tab-label"
              style={isNotification ? { color: "#DD4C91" } : {}}
            >
              Notification
            </p>
          </div> */}
            {/* <div
            className="settings-bar_tab-container"
            onClick={onClickHelpcenter}
          >
            <p
              className="settings-bar_tab-label"
              style={isHelpcenter ? { color: "#DD4C91" } : {}}
            >
              Help Center
            </p>
          </div> */}
          </div>
        )}
        <div className="settings-body">
          {isGeneral ? generalSettings : null}
          {isSubscription ? subscriptionSettings : null}
        </div>
      </div>
    </div>
  );
};

export default SettingsGrid;
